import { isDev } from "../isDev"

export const COURSES_KO = [
  // {
  //   label: "BEGINNER",
  //   title: "Framer: 인터렉티브 디자인 시스템",
  //   key: "framer-interactive-design-system",
  //   excerpt:
  //     "인터렉티브 디자인 시스템을 Framer 를 이용하여 처음 부터 만들어 봅니다",
  //   courseParts: [
  //     {
  //       label: "FORMS",
  //       key: "forms",
  //     },
  //     {
  //       label: "PROGRESS",
  //       key: "progress",
  //     },
  //     {
  //       label: "NAVIGATION",
  //       key: "navigation",
  //     },
  //     {
  //       label: "SURFACES",
  //       key: "surfaces",
  //     },
  //     {
  //       label: "CONTENT ─ ADVANCED",
  //       key: "content-advanced",
  //     },
  //     {
  //       label: "FORMS ─ ADVANCED",
  //       key: "forms-advanced",
  //     },
  //   ],
  //   illust: "",
  //   show: ["course-cards", "post-layout", "learn"],
  // },
  {
    label: "BEGINNER",
    title: "프레이머 101",
    key: "framer-101",
    excerpt: "프레이머를 시작하는 모든 분들을 위한",
    courseParts: [
      {
        label: "프레이머 기초: 그래픽",
        key: "framer-basics-graphics",
      },
      {
        label: "프레이머 기초: 인터렉션",
        key: "framer-basics-interaction",
      },
    ],
    illust: "",
    show: ["course-cards", "post-layout", "learn"],
  },
  {
    label: "BEGINNER",
    title: "애플 웹사이트 프레이머 클론",
    key: "clone-apple-website-framer",
    excerpt:
      "프레이머를 이용해 애플 웹사이트를 클론하는 방법을 배워 봅니다. 이 과정에는 웹사이트를 만들때 필요한 그래픽을 프레이머에서 그리는 방법과, 세계 최고 수준의 스크롤 인터렉션을 애플처럼 프레이머에서 만드는 방법이 포함 되어있습니다.",
    courseParts: [
      {
        label: "코스 소개",
        key: "course-introduction",
      },
      {
        label: "애플 웹사이트: 그래픽",
        key: "apple-website-graphics",
      },
      {
        label: "애플 웹사이트: 인터렉션",
        key: "apple-website-interaction",
      },
      {
        label: "프레이머 기초: 반응형 그래픽",
        key: "framer-basics-responsive-graphic",
        waitList: true,
      },
      {
        label: "애플 웹사이트: 반응형 인터렉션",
        key: "apple-website-responsive-interaction",
        waitList: true,
      },
    ],
    illust: "",
    show: ["course-cards", "post-layout", "learn"],
  },
  // {
  //   label: "BEGINNER",
  //   title: "Framer: 완벽 가이드 ─ Smart Components",
  //   key: "framer-the-complete-guide",
  //   excerpt:
  //     "Framer 의 기초 내용 부터 고급 내용까지 마스터 해 보세요. 그리고 여러분 만의 프로토타입과 인터렉티브 디자인 시스템을 만드세요.",
  //   courseParts: [
  //     {
  //       label: "Framer 전체적으로 살펴보기",
  //       key: "framer-overview",
  //     },
  //     {
  //       label: "기초 인터렉션 (Smart Component 알아보기)",
  //       key: "smart-component-basics",
  //     },
  //     {
  //       label: "심화 인터렉션 (Smart Component 심화)",
  //       key: "smart-component-advanced",
  //     },
  //   ],
  //   illust: "",
  //   show: ["course-cards", "post-layout", "learn"],
  // },
  {
    label: "BEGINNER",
    title: "코드가 처음인 사람을 위한\nModern HTML CSS",
    key: "modern-html-css",
    excerpt:
      "완전 코드를 처음 배우는 상태에서, 필수적이고 강력한 현대의 HTML & CSS를 배워 봅니다. 그리고 Instagram 웹사이트를 똑같이 만들어 봅니다.",
    courseParts: [
      {
        label: "HTML Basics",
        key: "html-basics",
      },
      {
        label: "CSS Basics",
        key: "css-basics",
      },
      {
        label: "Instagram Clone",
        key: "instagram-clone",
      },
    ],
    illust: "",
    show: ["course-cards", "post-layout", "learn"],
  },
  {
    label: "BEGINNER",
    title: "코드가 처음인 사람을 위한 React JS",
    key: "react",
    excerpt:
      "작성한 HTML & CSS 코드를, 재사용 가능한 React 컴포넌트 시스템으로 만듭니다. 그리고 Instagram 웹사이트에 다크 테마를 추가해 봅니다",
    courseParts: [
      {
        label: "JavaScript Basics",
        key: "javascript-basics",
      },
      {
        label: "React Component",
        key: "react-component",
      },
      {
        label: "CSS-in-JS",
        key: "css-in-js",
      },
      {
        label: "My Button Component",
        key: "my-button-component",
      },
      {
        label: "Instagram - CSS to React Components",
        key: "instagram-components",
      },
      {
        label: "Theming With CSS Variables",
        key: "theming-with-css-variables",
      },
    ],
    illust: "",
    show: ["course-cards", "post-layout", "learn"],
  },
  // {
  //   label: "INTERMEDIATE",
  //   title: "디자인 시스템과 Framer",
  //   key: "design-systems-and-framer",
  //   excerpt:
  //     "React 컴포넌트들을 Framer를 이용해 관리 가능한 시스템으로 바꾸어 봅니다",
  //   courseParts: [
  //     {
  //       label: "React Components on Framer",
  //       key: "react-components-on-framer",
  //     },
  //     {
  //       label: "Instagram Design System",
  //       key: "instagram-design-system",
  //     },
  //   ],
  //   illust: "",
  //   show: ["course-cards", "post-layout", "learn"],
  // },
  // {
  //   label: "INTERMEDIATE",
  //   title: "Framer Desktop을 이용한 빠른 프로토타이핑",
  //   key: "fast-prototyping",
  //   excerpt:
  //     "Framer Desktop 에서 Override를 이용하여 빠르게 프로토 타입들을 만들어 봅니다",
  //   courseParts: [
  //     {
  //       label: "Lv.1-1: 빠른 프로토타이핑 (Imperative)",
  //       key: "1-1",
  //     },
  //     {
  //       label: "Lv.1-2: 더 많은 Responses & Triggers",
  //       key: "1-2",
  //     },
  //     {
  //       label: "Lv.2: JavaScript Basics (Condition & Variables)",
  //       key: "2",
  //     },
  //     {
  //       label: "Lv.3-1: Controlling States (Override + Data)",
  //       key: "3-1",
  //     },
  //   ],
  //   illust: "",
  //   show: ["course-cards", "post-layout", "learn"],
  // },
]
